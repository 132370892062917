import http from './../plugins/axios'

export default class AuthService {

	static async login (data) {
		let response = await http.post(
			'https://us-central1-mobiplus-console.cloudfunctions.net/login',
			data
		)
		return response.data
	}
}