<template lang="pug">

  v-container
    v-snackbar(v-model="snackbar" top rounded="pill") {{ message }}
    v-row(justify="center" align-content="center" style="height:100vh")
      v-col(md="4")
        v-card(elevation="10")
          v-card-text
            v-img(
              width="100%"
              :src="require('./../assets/logo-mobiplus-preto.png')")
            v-text-field(
              outlined dense
              color="cyan"
              label="Email"
              v-model="user.email"
            )
            v-text-field(
              outlined dense
              color="cyan"
              @keyup.enter="login"
              label="Senha" type="password"
              v-model="user.password"
            )

            v-btn(
              block color="cyan"
              depressed dark
              :loading="loading"
              @click="login"
            ) entrar
</template>

<script>
import AuthService from "../services/AuthService";
import LocalStorage from "../services/generics/LocalStorage";
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      snackbar: false,
      message: '',
      user: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    login() {
      this.loading = true;

      AuthService.login(this.user)
        .then(_ => {
          this.loading = false;
          console.log({_})

          if (_.plataformas.storagePlus !== null) {
            if (_.plataformas.storagePlus) {
              LocalStorage.store('user', _.uid)
              this.$router.push({name: 'Home'})
              return
            }
            this.message = 'Usuário sem acesso a este sistema'
          }
          this.snackbar = true
        }).catch(err => {
        this.message = err.response.data.message
        this.loading = false;

        this.snackbar = true
      })
    }
  },
}
</script>

<style scoped>

</style>